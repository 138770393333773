import { useState, useEffect, useRef } from "react";
import Navbar from "./js/components/Navbar";
import { Alerts } from "./js/miscs/Alerts";
import { PageTypes } from "./js/miscs/PageTypes";
import About from "./js/pages/About";
import Blog from "./js/pages/Blog";
import Contact from "./js/pages/Contact";
import Home from "./js/pages/Home";
import Portfolio from "./js/pages/Portfolio";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import "./styles/style.css";
import Loading from "./js/pages/Loading";
import Footer from "./js/components/Footer";
import LegalDisclaimer from "./js/components/LegalDisclaimer";

//TODO Favicon, logo 192 & 512

function App() {
    const [page, setPage] = useState(PageTypes.NONE);
    const [pageElement, setPageElement] = useState(<Loading />);
    const [disclaimerVisible, setDisclaimerVisible] = useState(true);
    const { i18n } = useTranslation();
    var idxEaster = 0;

    const containerRef = useRef<HTMLDivElement>(null);

    const { t } = useTranslation();

    const pageChangeHandler = (newPage: PageTypes) => {
        setPage(newPage);
        switch (newPage) {
            case PageTypes.HOME:
                setPageElement(<Home pageHandler={pageChangeHandler} />);
                break;
            case PageTypes.BLOG:
                setPageElement(<Blog />);
                break;
            case PageTypes.PORTFOLIO:
                setPageElement(<Portfolio />);
                break;
            case PageTypes.ABOUT:
                setPageElement(<About pageHandler={pageChangeHandler} />);
                break;
            case PageTypes.CONTACT:
                setPageElement(<Contact />);
                break;
            default:
                alertsChangeHandler(Alerts.NOT_FOUND_ERROR);
                setPage(PageTypes.HOME);
        }
    };

    const alertsChangeHandler = (newAlertValue: Alerts, extraProps?: string): void => {
        switch (newAlertValue) {
            case Alerts.NOT_FOUND_ERROR:
                toast.error(t(newAlertValue));
                break;
            case Alerts.FORM_INVALID:
                toast.warning(t(newAlertValue));
                break;
            case Alerts.FORM_SENT:
                toast.success(t(newAlertValue));
                break;
        }
    };

    useEffect(() => {
        if (window.location.hostname === "www.agartha-dev.com" && window.location.protocol !== "https:") {
            window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
        }

        const PATH = new URLSearchParams(window.location.search).get("page");
        if (PATH) {
            var value: PageTypes = PageTypes[PATH.toUpperCase() as keyof typeof PageTypes];
            if (!value) {
                alertsChangeHandler(Alerts.NOT_FOUND_ERROR);
                pageChangeHandler(PageTypes.HOME);
            } else pageChangeHandler(value);
        } else {
            pageChangeHandler(PageTypes.HOME);
        }
        navigator.language === "fr" ? i18n.changeLanguage("fr") : i18n.changeLanguage("en");
    }, []);

    useEffect(() => {
        const nextURL = `${window.location.origin}?page=${page}`;
        const nextTitle = `Agartha Développement : ${t(`pages.${page}.title`)}`;
        const nextState = {};
        window.history.replaceState(nextState, nextTitle, nextURL);
    }, [page]);

    const happyEaster = (event: React.MouseEvent) => {
        idxEaster++;
        if (idxEaster > 10) {
            setPageElement(
                <div style={{ display: "flex", gap: "50px", alignItems: "center", flexFlow: "column nowrap" }}>
                    <img style={{ maxHeight: "50vh", maxWidth: "100%" }} src="../../assets/img/various/mow.jpg" />
                    <h1>{t("pages.home.easter")}</h1>
                    <a href="https://www.linktr.ee/Peachesnplushies">Credit : peachesnplushies</a>
                </div>,
            );
        }
    };

    return (
        <>
            <ToastContainer />
            <Navbar pageHandler={pageChangeHandler} page={page} />
            <div id="container" ref={containerRef}>
                {pageElement}
            </div>
            <LegalDisclaimer isVisible={disclaimerVisible} setIsVisible={setDisclaimerVisible} />
            <Footer pageHandler={pageChangeHandler} easter={happyEaster} />
        </>
    );
}

export default App;
