import { faCode, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
    techs: Array<string>;
    iconsMap: Map<string, IconDefinition>;
};

function TechsList({ techs, iconsMap }: Props): any {
    function findIcon(iconName: string): IconDefinition {
        const iconFound = iconsMap.get(iconName);
        return iconFound ? iconFound : faCode;
    }

    return techs.map((el) => {
        return (
            <li key={el}>
                <FontAwesomeIcon icon={findIcon(el)} /> - {el}
            </li>
        );
    });
}

export default TechsList;
