import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { PageTypes } from "../miscs/PageTypes";

type Props = {
    name: string;
    pageType: PageTypes;
    icon: IconDefinition;
    pageHandler: (newValue: PageTypes) => void;
    page: PageTypes;
};

function Navitem({ name, icon, pageType, pageHandler, page }: Props) {
    const handleClick = (event: React.MouseEvent) => {
        event.preventDefault();
        pageHandler(pageType);
    };

    return (
        <a href={`https://www.agartha-dev.com/${pageType}`} className={page === pageType ? "nav_item active" : "nav_item"} onClick={(e) => handleClick(e)}>
            <FontAwesomeIcon icon={icon}></FontAwesomeIcon>
            {name}
        </a>
    );
}

export default Navitem;
