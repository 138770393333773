import { useState } from "react";
import Navitem from "./Navitem";
import { faHouse, faNewspaper, faCode, faAddressCard, faEnvelope, faBars, faCheese, faCrown } from "@fortawesome/free-solid-svg-icons";
import { PageTypes } from "../miscs/PageTypes";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from "../i18n";

type Props = {
    pageHandler: (newValue: PageTypes) => void;
    page: PageTypes;
};

function Navbar({ pageHandler, page }: Props) {
    const { t } = useTranslation();
    const [isCollapsed, setIsCollapsed] = useState(true);

    const pageHandlerResponsive = (newValue: PageTypes) => {
        pageHandler(newValue);
        setIsCollapsed(true);
    };

    const changeLanguageHandler = (e: React.MouseEvent, newLanguage: "fr" | "en") => {
        i18n.changeLanguage(newLanguage);
        (e.target as HTMLButtonElement).classList.toggle("active");
    };

    return (
        <header>
            <nav id="navbar">
                <div id="nav-left">
                    <img onClick={() => pageHandler(PageTypes.HOME)} src="../../assets/svg/logo_name_dark.svg" alt="Agartha Développement" />
                    <div id="languages">
                        <button id="fr" onClick={(e) => changeLanguageHandler(e, "fr")}>
                            <FontAwesomeIcon icon={faCheese} /> - Fr
                        </button>
                        <button id="en" onClick={(e) => changeLanguageHandler(e, "en")}>
                            <FontAwesomeIcon icon={faCrown} /> - En
                        </button>
                    </div>
                </div>
                <div id="nav-right">
                    <div id="items" style={{ width: isCollapsed ? "" : "100%" }}>
                        <Navitem name={t("pages.home.title")} pageType={PageTypes.HOME} icon={faHouse} pageHandler={pageHandlerResponsive} page={page} />
                        <Navitem name={t("pages.blog.title")} pageType={PageTypes.BLOG} icon={faNewspaper} pageHandler={pageHandlerResponsive} page={page} />
                        <Navitem name={t("pages.portfolio.title")} pageType={PageTypes.PORTFOLIO} icon={faCode} pageHandler={pageHandlerResponsive} page={page} />
                        <Navitem name={t("pages.about.title")} pageType={PageTypes.ABOUT} icon={faAddressCard} pageHandler={pageHandlerResponsive} page={page} />
                        <Navitem name={t("pages.contact.title")} pageType={PageTypes.CONTACT} icon={faEnvelope} pageHandler={pageHandlerResponsive} page={page} />
                    </div>
                </div>
                <div id="hamburger" onClick={() => setIsCollapsed(!isCollapsed)}>
                    <FontAwesomeIcon icon={faBars} />
                </div>
            </nav>
        </header>
    );
}

export default Navbar;
