import { faGoogle, faWordpress, faJs, faHtml5, faCss3, faJava, faReact, faSass, faAws, faNodeJs, faAndroid } from "@fortawesome/free-brands-svg-icons";
import { faAtom, IconDefinition, faCloud, faCogs } from "@fortawesome/free-solid-svg-icons";
import TechsList from "./TechsList";
import { Trans } from "react-i18next";

export type WorkElement = {
    shortName: string;
    projectName: string;
    dates: { start: string; end: string };
    category: string;
    techs: Array<string>;
    description: string;
    backgroundUrl: string;
    projectLink: string;
};

function Work({ projectName, dates, category, techs, shortName, description, backgroundUrl, projectLink }: WorkElement) {
    const map = new Map<string, IconDefinition>();

    function initMap(): void {
        map.set("html", faHtml5);
        map.set("css", faCss3);
        map.set("js", faJs);
        map.set("electron", faAtom);
        map.set("react", faReact);
        map.set("wp", faWordpress);
        map.set("rest", faCloud);
        map.set("aws", faAws);
        map.set("gtm", faGoogle);
        map.set("java", faJava);
        map.set("sass", faSass);
        map.set("node", faNodeJs);
        map.set("android", faAndroid);
        map.set("ts", faCogs);
    }

    initMap();
    return (
        <div className="rea" id={shortName} style={{ backgroundImage: `url("../../assets/img/projects/${backgroundUrl}")` }}>
            <div className="rea_desc">
                <h2 className="rea_cat">{category}</h2>
                <h1 className="rea_name">{projectName}</h1>
                <h3 className="rea_date">{dates.end !== "" ? `${dates.start} - ${dates.end}` : dates.start}</h3>
                <ul>
                    <TechsList techs={techs} iconsMap={map} />
                </ul>
                <p>
                    <Trans>{description}</Trans>
                </p>
                {projectLink !== "" && <a className="rea_link">{projectLink}</a>}
            </div>
        </div>
    );
}

export default Work;
