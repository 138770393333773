import { Trans, useTranslation } from "react-i18next";
import { PageTypes } from "../miscs/PageTypes";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef, useEffect } from "react";

type Props = {
    pageHandler: (newValue: PageTypes) => void;
};

function About({ pageHandler }: Props) {
    const { t } = useTranslation();
    const heroImgContainerRef = useRef<HTMLDivElement>(null);
    const heroTextContainerRef = useRef<HTMLDivElement>(null);
    var heroDivsRef = [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)];

    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        gsap.fromTo(heroImgContainerRef.current, { opacity: 0 }, { opacity: 1, duration: 3 });
        gsap.fromTo(heroTextContainerRef.current, { opacity: 0 }, { opacity: 1, duration: 3, delay: 1 });

        heroDivsRef.forEach((el) => gsap.fromTo(el.current, { opacity: 0 }, { opacity: 1, duration: 3, scrollTrigger: { trigger: el.current } }));
    }, []);

    return (
        <div id="about-container">
            <div id="about-hero" className="full_container">
                <div ref={heroTextContainerRef}>
                    <h1>{t("pages.about.left.title")}</h1>
                    <Trans>
                        <p>{t("pages.about.left.p1")}</p>
                    </Trans>
                    <button onClick={() => pageHandler(PageTypes.CONTACT)}>{t("pages.about.left.button")}</button>
                </div>
                <div ref={heroImgContainerRef}>
                    <div id="title" className="info_bubbles">
                        <p>{t("pages.about.right.title")}</p>
                    </div>
                    <div id="experience" className="info_bubbles">
                        <p>{t("pages.about.right.experience")}</p>
                    </div>
                </div>
            </div>
            <div id="hero-1" className="flex_inline hero_container" ref={heroDivsRef[0]}>
                <img src="../../assets/img/various/about_img1.jpg" alt="" loading="lazy" />
                <div className="hero_text">
                    <div>
                        <h1 className="title">
                            <Trans>{t("pages.about.hero1.title")}</Trans>
                        </h1>
                        <h3>
                            <Trans>{t("pages.about.hero1.undertitle")}</Trans>
                        </h3>
                    </div>
                    <div>
                        <p>
                            <Trans>{t("pages.about.hero1.p1")}</Trans>
                        </p>
                        <p>
                            <Trans>{t("pages.about.hero1.p2")}</Trans>
                        </p>
                    </div>
                </div>
            </div>
            <div id="hero-2" className="flex_inline hero_container" ref={heroDivsRef[1]}>
                <img src="../../assets/img/various/about_img2.jpg" alt="" loading="lazy" />
                <div className="hero_text">
                    <div>
                        <h1 className="title">
                            <Trans>{t("pages.about.hero2.title")}</Trans>
                        </h1>
                        <h3>
                            <Trans>{t("pages.about.hero2.undertitle")}</Trans>
                        </h3>
                    </div>
                    <div>
                        <p>
                            <Trans>{t("pages.about.hero2.p1")}</Trans>
                        </p>
                        <p>
                            <Trans>{t("pages.about.hero2.p2")}</Trans>
                        </p>
                    </div>
                </div>
            </div>
            <div id="hero-3" className="flex_inline hero_container" ref={heroDivsRef[2]}>
                <img src="../../assets/img/various/about_img3.jpg" alt="" loading="lazy" />
                <div className="hero_text">
                    <div>
                        <h1 className="title">
                            <Trans>{t("pages.about.hero3.title")}</Trans>
                        </h1>
                        <h3>
                            <Trans>{t("pages.about.hero3.undertitle")}</Trans>
                        </h3>
                    </div>
                    <div>
                        <p>
                            <Trans>{t("pages.about.hero3.p1")}</Trans>
                        </p>
                        <p>
                            <Trans>{t("pages.about.hero3.p2")}</Trans>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
