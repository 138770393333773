import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import Loading from "./Loading";
import { toast } from "react-toastify";

function Contact() {
    const { t } = useTranslation();
    const [isSending, setIsSending] = useState<boolean>(false);
    const [token, setToken] = useState<string>();
    const nameRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const messageRef = useRef<HTMLTextAreaElement>(null);

    const onCaptchaChangeHandler = (_token: string | null) => {
        if (_token) setToken(_token);
        else toast.error(t("pages.contact.form.errors.recaptcha"));
    };

    const onSubmitHandler = (event: React.SyntheticEvent) => {
        event.preventDefault();
        console.log("going to submit");
        if (token) {
            try {
                setIsSending(true);
                const xhttp = new XMLHttpRequest();
                xhttp.onload = function () {
                    this.responseText === "ok" ? toast.success(t("pages.contact.form.success")) : toast.error(t(`pages.contact.form.errors.${this.responseText}`));
                    setIsSending(false);
                };
                xhttp.open("POST", `../../php/contact.php?name=${nameRef.current?.value}&email=${emailRef.current?.value}&subject=${messageRef.current?.value}&token=${token}`);
                xhttp.send();
            } catch (e) {
                console.error(e);
                toast.error(t("pages.contact.form.errors.error"));
                setIsSending(false);
            }
        } else toast.error(t("pages.contact.form.errors.recaptcha"));
    };

    return (
        <div id="contact-container" className="full_container">
            <div>
                <p>{t("pages.contact.left.p1")}</p>
                <img src="../../assets/img/various/lc_working_crop.jpg" />
                <p>{t("pages.contact.left.p2")}</p>
            </div>
            <div>
                <div>
                    <h2>{t("pages.contact.form.title")}</h2>
                    <form onSubmit={onSubmitHandler}>
                        <input type={"text"} ref={nameRef} placeholder={t("pages.contact.form.name.placeholder")} />
                        <input type={"email"} ref={emailRef} pattern={"^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"} placeholder={t("pages.contact.form.email.placeholder")} />
                        <textarea ref={messageRef} placeholder={t("pages.contact.form.message.placeholder")} />
                        <div id="recaptcha-container">
                            <ReCAPTCHA sitekey="6Lc1C7YgAAAAAOpm3UMc1G-o7EdiCklKQriigdKL" onChange={onCaptchaChangeHandler} theme="light" />
                        </div>
                        {isSending && <Loading />}
                        <p>{t("pages.contact.form.disclaimer")}</p>
                        <button type="submit">{t("pages.contact.form.submit")}</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Contact;
