import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { PageTypes } from "../miscs/PageTypes";

type Props = {
    pageHandler: (newPage: PageTypes) => void;
    easter: (event: React.MouseEvent) => void;
};

function Footer({ pageHandler, easter }: Props) {
    const { t } = useTranslation();

    return (
        <footer>
            <div id="footer-content" className="flex_inline">
                <div id="logo-quote">
                    <img src="../../assets/svg/logo_name_dark.svg" alt="" onClick={easter} />
                    <p>
                        <strong>{t("footer.categories.logo.logotext")}</strong>
                        {t("footer.categories.logo.logotext.author")}
                    </p>
                </div>

                <div>
                    <h3>{t("footer.categories.socials.title")}</h3>
                    <div id="socials">
                        <a href="https://www.linkedin.com/in/lohan-calot" target={"_blank"} rel="noreferrer" title="Linkedin">
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                        <a href="https://github.com/hylont" target={"_blank"} rel="noreferrer" title="Personnal and professionnal github">
                            <FontAwesomeIcon icon={faGithub} />
                        </a>
                    </div>
                </div>

                <div id="access-map">
                    <h3>{t("footer.categories.map.title")}</h3>
                    <ul>
                        <li>
                            <a href="" onClick={() => pageHandler(PageTypes.HOME)}>
                                {t("pages.home.title")}
                            </a>
                        </li>
                        <li>
                            <a href="" onClick={() => pageHandler(PageTypes.BLOG)}>
                                {t("pages.blog.title")}
                            </a>
                        </li>
                        <li>
                            <a href="" onClick={() => pageHandler(PageTypes.PORTFOLIO)}>
                                {t("pages.portfolio.title")}
                            </a>
                        </li>
                        <li>
                            <a href="" onClick={() => pageHandler(PageTypes.ABOUT)}>
                                {t("pages.about.title")}
                            </a>
                        </li>
                        <li>
                            <a href="" onClick={() => pageHandler(PageTypes.CONTACT)}>
                                {t("pages.contact.title")}
                            </a>
                        </li>
                    </ul>
                </div>

                <div id="get-in-touch">
                    <h3>{t("footer.categories.contact.title")}</h3>

                    <a href="mailto:contact@agartha-dev.com">
                        <FontAwesomeIcon icon={faEnvelope} />
                        contact@agartha-dev.com
                    </a>
                </div>
            </div>
            <p>{t("footer.disclaimer")}</p>
        </footer>
    );
}

export default Footer;
