import { faClose, faCookieBite } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

type Props = {
    isVisible: boolean;
    setIsVisible: Dispatch<SetStateAction<boolean>>;
};

function LegalDisclaimer({ isVisible, setIsVisible }: Props) {
    const { t } = useTranslation();

    return (
        <>
            {isVisible && (
                <div id="legal-container">
                    <button onClick={() => setIsVisible(false)}>
                        <FontAwesomeIcon icon={faClose} />
                    </button>
                    <div>
                        <p>
                            <FontAwesomeIcon icon={faCookieBite} />
                            {t("legaldiclaimer")}
                        </p>
                        <ul>
                            <li>{t("legaldiclaimer.1")}</li>
                            <li>{t("legaldiclaimer.2")}</li>
                        </ul>
                    </div>
                </div>
            )}
        </>
    );
}

export default LegalDisclaimer;
