import { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Loading from "./Loading";
import { PageTypes } from "../miscs/PageTypes";

type Props = {
    pageHandler: (newValue: PageTypes) => void;
};

function Home({ pageHandler }: Props) {
    //refs
    const heroImgContainerRef = useRef<HTMLDivElement>(null);
    const spawnerRef = useRef<HTMLDivElement>(null);
    const logosRef = useRef<HTMLDivElement>(null);
    const heroRef = useRef<HTMLDivElement>(null);
    var heroDivsRef = [heroRef, useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)];
    var partnersAnchorsRef = [useRef<HTMLAnchorElement>(null), useRef<HTMLAnchorElement>(null), useRef<HTMLAnchorElement>(null)];
    const latestPostsRef = useRef<HTMLDivElement>(null);

    //states
    const [loadingPosts, setLoadingPosts] = useState(false);

    //miscs hooks
    const { t, i18n } = useTranslation();

    const postsRequest = () => {
        // if (latestPostsRef.current?.innerHTML === "") {
        //     console.log("going to request !");
        //     try {
        //         setLoadingPosts(true);
        //         const xhttp = new XMLHttpRequest();
        //         xhttp.onload = function () {
        //             if (latestPostsRef.current) {
        //                 latestPostsRef.current.innerHTML = this.responseText;
        //             }
        //             setLoadingPosts(false);
        //         };
        //         xhttp.open("GET", `../../php/get_articles.php?lang=${i18n.language}&limit=3`);
        //         xhttp.send();
        //     } catch (e) {
        //         console.error(e);
        //         latestPostsRef.current?.append(`<p>${t("pages.home.posts.error")}</p>`);
        //         setLoadingPosts(false);
        //     }
        // }
    };

    gsap.registerPlugin(ScrollTrigger);

    const appendLogos = () => {
        const IMGS_PATH = "../../assets/img/tech_logos/";
        const LOGOS_FILENAMES = ["react.png", "ue.png", "unity.png", "aws.png", "html5.png", "css.png", "gamemaker.png", "java.png", "js.png", "vba.png"];
        const DURATION = 20; //seconds to complete a rotation
        const DELAY = (DURATION / LOGOS_FILENAMES.length) * 1000;

        //appends all logos with the filenames and sets their initial style
        for (var idxLogo = 0; idxLogo < LOGOS_FILENAMES.length; idxLogo++) {
            if (logosRef.current) {
                (logosRef.current as HTMLDivElement).innerHTML += `<img
                    src="${IMGS_PATH}${LOGOS_FILENAMES[idxLogo]}"
                    style="
                        position: absolute;
                        opacity: 0;
                        left: ${spawnerRef.current ? (spawnerRef.current as HTMLDivElement).getBoundingClientRect().x + "px" : ""};
                        top: ${spawnerRef.current ? (spawnerRef.current as HTMLDivElement).getBoundingClientRect().y + "px" : ""};
                        animation-delay : ${idxLogo * DELAY}ms;
                        animation-duration : ${DURATION}s;
                    "
                />`;
            }
        }
        //sets the opacity of all logos to 1
        setTimeout(() => {
            if (logosRef.current) {
                const CHILDRENS = (logosRef.current as HTMLDivElement).getElementsByTagName("img");
                for (let idxC = 0; idxC < CHILDRENS.length; idxC++) {
                    CHILDRENS[idxC].style.opacity = "1";
                }
            }
        }, DURATION * 1000 - DELAY * 0.5);
    };

    useEffect(() => {
        //add logos to the logos container, places them, specifies the animation's info and sets their opacity to 1 when all logos have been added
        setTimeout(() => {
            appendLogos();
        }, 2000);

        //fade effet on hero
        heroDivsRef.forEach((hero) => gsap.fromTo(hero.current, { opacity: 0 }, { opacity: 1, duration: 3, scrollTrigger: { trigger: hero.current } }));
        //posts related
        ScrollTrigger.create({
            trigger: "#latest-posts",
            onEnter: postsRequest,
        });

        //fade effet on partners
        for (let idxPartner = 0; idxPartner < partnersAnchorsRef.length; idxPartner++) {
            let current = partnersAnchorsRef[idxPartner].current;
            gsap.fromTo(current, { opacity: 0 }, { opacity: 1, duration: 1, delay: idxPartner + 1, scrollTrigger: { trigger: current } });
        }
    }, []);

    return (
        <div id="home-container" className="page-container ">
            <div id="hero" className="flex_inline hero_container" ref={heroRef}>
                <div className="hero_text">
                    <h1>
                        <Trans>{t("pages.home.hero.title")}</Trans>
                    </h1>
                    <p>{t("pages.home.hero.paragraph.1")}</p>
                    <p>{t("pages.home.hero.paragraph.2")}</p>
                    <div id="buttons">
                        <button onClick={() => pageHandler(PageTypes.CONTACT)}>{t("pages.home.hero.buttons.primary")}</button>
                        <button onClick={() => pageHandler(PageTypes.PORTFOLIO)}>{t("pages.home.hero.buttons.secondary")}</button>
                    </div>
                </div>
                <div id="hero-img-container" ref={heroImgContainerRef}>
                    <div id="spawner" ref={spawnerRef}></div>
                    <img src="../../assets/svg/logo_bright_darkbg.svg" loading="eager" alt="Agartha Développement"></img>
                </div>
            </div>
            <div id="latest-posts" className="flex_inline_full" style={{ display: "none" }}>
                <div ref={latestPostsRef}>{loadingPosts && <Loading />}</div>
            </div>
            <div id="hero-2" className="flex_inline hero_container" ref={heroDivsRef[1]}>
                <img src="../../assets/img/various/html_code.jpg" alt="" loading="lazy" />
                <div className="hero_text">
                    <div>
                        <h1 className="title">
                            <Trans>{t("pages.home.hero2.title")}</Trans>
                        </h1>
                        <h3>
                            <Trans>{t("pages.home.hero2.undertitle")}</Trans>
                        </h3>
                    </div>
                    <div>
                        <p>
                            <Trans>{t("pages.home.hero2.p1")}</Trans>
                        </p>
                        <p>
                            <Trans>{t("pages.home.hero2.p2", { kissAttributes: "href='https://en.wikipedia.org/wiki/KISS_principle' target='_blank'  rel='noreferrer'" })}</Trans>
                        </p>
                    </div>
                </div>
            </div>
            <div id="hero-3" className="flex_inline hero_container" ref={heroDivsRef[2]}>
                <img src="../../assets/img/various/img1.jpg" alt="" loading="lazy" />
                <div className="hero_text">
                    <div>
                        <h1 className="title">
                            <Trans>{t("pages.home.hero3.title")}</Trans>
                        </h1>
                        <h3>
                            <Trans>{t("pages.home.hero3.undertitle")}</Trans>
                        </h3>
                    </div>
                    <p>{t("pages.home.hero3.p1")}</p>
                </div>
            </div>
            <div className="flex_inline_full" id="partners" ref={heroDivsRef[3]}>
                <h1>{t("pages.home.partners.title")}</h1>
                <div>
                    <a href="https://aws.amazon.com/" target={"_blank"} rel="noreferrer" ref={partnersAnchorsRef[0]}>
                        <img src="../../assets/img/partners/aws.svg" alt="Amazon web services"></img>
                    </a>
                    <a href="https://monsystemedechauffage.com/" target={"_blank"} rel="noreferrer" ref={partnersAnchorsRef[1]}>
                        <img src="../../assets/img/partners/msdc_logo.png" alt="Mon Système de chauffage"></img>
                    </a>
                    <a href="https://aplytek.fr/" target={"_blank"} rel="noreferrer" ref={partnersAnchorsRef[2]}>
                        <img src="../../assets/img/partners/aplytek_logo.png" alt="Aplytek"></img>
                    </a>
                </div>
            </div>
            <hr></hr>
            <div id="logos" ref={logosRef}></div>
        </div>
    );
}

export default Home;
