import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { WorkElement } from "../components/Work";
import Work from "../components/Work";
import gsap from "gsap";
import { useTranslation } from "react-i18next";
import data from "../../locales/works.json";
import { useEffect, useRef } from "react";

function Portfolio() {
    console.log(data);
    const { i18n } = useTranslation();
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        gsap.fromTo(containerRef.current, { opacity: 0 }, { opacity: 1, duration: 3 });
    }, []);

    return (
        <div id="portfolio-container" ref={containerRef} className="full_container">
            <Swiper
                // install Swiper modules
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={0}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                onSwiper={(swiper) => console.log("Swiper Ready", swiper)}
                style={{ height: "100%" }}
                autoplay={{ delay: 15000 }}
            >
                {i18n.language === "en" &&
                    data.en.projects.map((el: WorkElement) => {
                        return (
                            <SwiperSlide key={"ss-" + el.shortName}>
                                <Work
                                    key={el.shortName}
                                    projectName={el.projectName}
                                    dates={el.dates}
                                    category={el.category}
                                    techs={el.techs}
                                    shortName={el.shortName}
                                    description={el.description}
                                    backgroundUrl={el.backgroundUrl}
                                    projectLink={el.projectLink}
                                />
                            </SwiperSlide>
                        );
                    })}
                {i18n.language === "fr" &&
                    data.fr.projects.map((el: WorkElement) => {
                        return (
                            <SwiperSlide key={"ss-" + el.shortName}>
                                <Work
                                    key={el.shortName}
                                    projectName={el.projectName}
                                    dates={el.dates}
                                    category={el.category}
                                    techs={el.techs}
                                    shortName={el.shortName}
                                    description={el.description}
                                    backgroundUrl={el.backgroundUrl}
                                    projectLink={el.projectLink}
                                />
                            </SwiperSlide>
                        );
                    })}
            </Swiper>
        </div>
    );
}

export default Portfolio;
